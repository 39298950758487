import React, {useState} from 'react';
import {OrderModel} from "Apis/Models/OrderModel";
import FlexRow from "Components/FlexRow";
import {Col, Row, Space, Table} from "antd";
import StyledButton from "Components/StyledButton";
import {OfferModel} from "Apis/Models/OfferModel";
import Pill from "Components/Pill";
import {getOfferStateAsString, getOfferStateColor, getOfferTypeAsString} from "Utils/OfferUtils";
import moment from "moment";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import {getInvoiceStateAsString, getInvoiceStateColor, getInvoiceTypeAsString} from "Utils/InvoiceUtils";
import {useHistory} from "react-router-dom";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {useCreateInvoiceFromOrder} from "Apis/InvoiceApi";
import {useOrderDocuments, useOrderInvoicingItems} from "Apis/OrderApi";
import Modal from "antd/es/modal";
import { getNumberAsCurrency} from "Utils/NumberUtils";
import {useSelectEntries} from "Hooks/UseSelectEntries";

interface Props {
    order: OrderModel
}
const OrderDocuments = ({order}: Props) => {

    const history = useHistory();

    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState<boolean>(false);
    const [isAdvance, setIsAdvance] = useState<boolean>(false);

    const {data: documents, isLoading: isLoadingDocuments} = useOrderDocuments(order.id)
    const {data: invoicingItems} = useOrderInvoicingItems(order.id)
    const [createInvoiceFromOrderMutation, {isLoading: isCreatingInvoiceFromOrder}] = useCreateInvoiceFromOrder();

    const [isAllTimeEntriesSelected, selectedTimeEntries, isTimeEntrySelected, toggleTimeEntrySelected, toggleAllTimeEntries] = useSelectEntries(invoicingItems?.timeEntries.map(x => x.id!) ?? [])
    const [isAllAgreementsSelected, selectedAgreements, isAgreementSelected, toggleAgreementSelected, toggleAllAgreements] = useSelectEntries(invoicingItems?.agreements.map(x => x.id!) ?? [])
    const [isAllOffersSelected, selectedOffers, isOfferSelected, toggleOfferSelected, toggleAllOffers] = useSelectEntries(invoicingItems?.offers.map(x => x.id!) ?? [])
    const [isAllMaterialsSelected, selectedMaterials, isMaterialSelected, toggleMaterialSelected, toggleAllMaterials] = useSelectEntries(invoicingItems?.materials.map(x => x.id!) ?? [])
    const [joinMaterials, setJoinMaterials] = useState<boolean>(false);
    const [joinTimeEntries, setJoinTimeEntries] = useState<boolean>(false);
    const [includeTimesheet, setIncludeTimesheet] = useState<boolean>(true);

    const createInvoice = async () => {
        const response = await createInvoiceFromOrderMutation({
            orderId: order.id,
            isAdvance,
            selectedTimeEntries,
            selectedOffers,
            selectedAgreements,
            selectedMaterials,
            joinMaterials,
            joinTimeEntries,
            includeTimesheet
        });

        if (response) {
            openSuccessNotification('Success', 'Fakturakladde oprettet')

            history.push(`/economy/invoices/edit/${response.id}`)
        }
    }

    const openInvoicingModal = (isAdvance: boolean) => {
        setIsAdvance(isAdvance);
        setShowCreateInvoiceModal(true)
    }

    return (
        <>
            <div style={{paddingBottom: 50}}>
                <FlexRow justify={"end"}>
                    <Space>
                        <StyledButton onClick={() => openInvoicingModal(true)} color={'default'} loading={isCreatingInvoiceFromOrder} disabled={isCreatingInvoiceFromOrder}>Opret aconto faktura</StyledButton>
                        <StyledButton onClick={() => openInvoicingModal(false)} loading={isCreatingInvoiceFromOrder} disabled={isCreatingInvoiceFromOrder}>Opret slutfaktura</StyledButton>
                    </Space>
                </FlexRow>

                <Table
                    dataSource={documents?.offers}
                    pagination={false}
                    loading={isLoadingDocuments}
                    rowKey={(record: OfferModel) => record.id.toString()}
                    rowClassName={"clickable"}
                    onRow={(record) => {
                        return {
                            onClick: () => history.push(`/projects/offers/${record.id}`),
                        };}
                    }
                    size={"small"}
                >
                    <Table.Column title={"Type"} dataIndex={"offerType"} render={(text, record: OfferModel) => (
                        <FlexRow justify={"start"}>
                            <Pill color={getOfferStateColor(record)} />
                            <FlexRow align={"start"} justify={"start"} direction={"column"}>
                                <span>
                                    {getOfferTypeAsString(record.offerType)}
                                </span>
                                <span style={{fontSize: '.7em'}}>
                                    {getOfferStateAsString(record)}
                                </span>
                            </FlexRow>
                        </FlexRow>
                    )} />
                    <Table.Column title={"Type"} dataIndex={"title"} render={(value, record: OfferModel) => getOfferTypeAsString(record.offerType)} />
                    <Table.Column title={"Status"} dataIndex={"state"} render={(value, record: OfferModel) => getOfferStateAsString(record)} />
                    <Table.Column title={"Titel"} dataIndex={"title"} />
                    <Table.Column title={"Dato"} dataIndex={"date"} render={(value, record: OfferModel) => moment(record.date).format('DD MMMM YYYY')} />
                </Table>
            </div>

            <div>
                <Table
                    dataSource={documents?.invoices}
                    pagination={false}
                    loading={isLoadingDocuments}
                    rowKey={(record: InvoiceModel) => record.id.toString()}
                    rowClassName={"clickable"}
                    onRow={(record) => {
                        return {
                            onClick: () => history.push(`/economy/invoices/edit/${record.id}`),
                        };}
                    }
                    size={"small"}
                >
                    <Table.Column title={"Type"} dataIndex={"offerType"} render={(text, record: InvoiceModel) => (
                        <FlexRow justify={"start"}>
                            <Pill color={getInvoiceStateColor(record)} />
                            <FlexRow align={"start"} justify={"start"} direction={"column"}>
                                <span>
                                    {getInvoiceTypeAsString(record.invoiceType)}
                                </span>
                                <span style={{fontSize: '.7em'}}>
                                    {getInvoiceStateAsString(record)}
                                </span>
                            </FlexRow>
                        </FlexRow>
                    )} />
                    <Table.Column title={"Type"} dataIndex={"title"} render={(value, record: InvoiceModel) => record.invoiceType === 'Invoice' ? 'Faktura' : 'Kreditnota'} />
                    <Table.Column title={"Status"} dataIndex={"state"} render={(value, record: InvoiceModel) => getInvoiceStateAsString(record)} />
                    <Table.Column title={"Titel"} dataIndex={"title"} />
                    <Table.Column title={"Dato"} dataIndex={"date"} render={(value, record: InvoiceModel) => record.date ? moment(record.date).format('DD MMMM YYYY') : ''} />
                </Table>
            </div>

            <Modal visible={showCreateInvoiceModal} title={`Opret ${isAdvance ? 'aconto ' : 'slut'}faktura`}
                   onCancel={() => setShowCreateInvoiceModal(false)}
                   footer={false}
                   width={'60%'}
            >
                <FlexRow direction={"column"} align={"start"}>
                    <Space direction={"vertical"} style={{width: '100%'}}>

                        <div>
                            <Row gutter={[10, 10]} align={"middle"}>
                                <Col span={1}>
                                    <input type={"checkbox"} checked={includeTimesheet} onChange={() => setIncludeTimesheet(!includeTimesheet)}/>
                                </Col>
                                <Col span={23}>
                                    <span>Inkluder timeseddel</span>
                                </Col>
                            </Row>
                        </div>
                        {invoicingItems?.offers && invoicingItems?.offers.length > 0 && (
                            <div>
                                <Row gutter={[10, 10]} align={"middle"}>
                                    <Col span={1}>
                                        <input type={"checkbox"} checked={isAllOffersSelected} onChange={() => toggleAllOffers()} />
                                    </Col>
                                    <Col span={23}>
                                        <span style={{...styles.headline}}>Tilbud & Overslag</span>
                                    </Col>
                                    {invoicingItems?.offers.map((offer) => (
                                        <>
                                            <Col span={1}>
                                                <input type={"checkbox"} onClick={() => toggleOfferSelected(offer.id!)} checked={isOfferSelected(offer.id)} readOnly  />
                                            </Col>
                                            <Col span={5}>{getOfferTypeAsString(offer.offerType)}</Col>
                                            <Col span={6}>{offer.title}</Col>
                                            <Col span={12}>
                                                <FlexRow justify={"end"}>
                                                    {getNumberAsCurrency(offer.priceExVat)} DKK excl. moms
                                                </FlexRow>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        )}

                        {invoicingItems?.agreements && invoicingItems?.agreements.length > 0 && (
                            <div>
                                <Row gutter={[10, 10]} align={"middle"}>
                                    <Col span={1}>
                                        <input type={"checkbox"} checked={isAllAgreementsSelected} onChange={() => toggleAllAgreements()} />
                                    </Col>
                                    <Col span={23}>
                                        <span style={{...styles.headline}}>Aftalesedler</span>
                                    </Col>
                                    {invoicingItems.agreements.map((agreement) => (
                                        <>
                                            <Col span={1}>
                                                <input type={"checkbox"} onClick={() => toggleAgreementSelected(agreement.id!)} checked={isAgreementSelected(agreement.id)} readOnly  />
                                            </Col>
                                            <Col span={11}>{agreement.title}</Col>
                                            <Col span={12}>
                                                <FlexRow justify={"end"}>
                                                    {getNumberAsCurrency(agreement.priceExVat!)} DKK excl. moms
                                                </FlexRow>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        )}

                        {invoicingItems?.timeEntries && invoicingItems?.timeEntries.length > 0 && (
                            <div>
                                <Row gutter={[10, 10]} align={"middle"}>
                                    <Col span={1}>
                                        <input type={"checkbox"} checked={isAllTimeEntriesSelected} onChange={() => toggleAllTimeEntries()} />
                                    </Col>
                                    <Col span={11}>
                                        <span style={{...styles.headline}}>Timer</span>
                                    </Col>
                                    <Col span={12}>
                                        <FlexRow justify={"end"}>
                                            <Space>
                                                <b>Samel timeregistrering i én linje</b>
                                                <input type={"checkbox"} checked={joinTimeEntries} onChange={() => setJoinTimeEntries(!joinMaterials)} />
                                            </Space>
                                        </FlexRow>
                                    </Col>
                                    {invoicingItems?.timeEntries.map((timeEntries) => (
                                        <>
                                            <Col span={1}>
                                                <input type={"checkbox"} onClick={() => toggleTimeEntrySelected(timeEntries.id!)} checked={isTimeEntrySelected(timeEntries.id)} readOnly  />
                                            </Col>
                                            <Col span={23}>{moment(timeEntries.from).format('DD MMMM YYYY')} - {moment(timeEntries.from).format('HH:mm')} - {moment(timeEntries.to).format('HH:mm')}</Col>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        )}

                        {invoicingItems?.materials && invoicingItems?.materials.length > 0 && (
                            <div>
                                <Row gutter={[10, 10]} align={"middle"}>
                                    <Col span={1}>
                                        <input type={"checkbox"} checked={isAllMaterialsSelected} onChange={() => toggleAllMaterials()} />
                                    </Col>
                                    <Col span={11}>
                                        <span style={{...styles.headline}}>Materialer</span>
                                    </Col>
                                    <Col span={12}>
                                        <FlexRow justify={"end"}>
                                            <Space>
                                                <b>Samle materialer i 1 linje</b>
                                                <input type={"checkbox"} checked={joinMaterials} onChange={() => setJoinMaterials(!joinMaterials)} />
                                            </Space>
                                        </FlexRow>
                                    </Col>
                                    {invoicingItems?.materials.map((material) => (
                                        <>
                                            <Col span={1}>
                                                <input type={"checkbox"} onClick={() => toggleMaterialSelected(material.id!)} checked={isMaterialSelected(material.id)} readOnly />
                                            </Col>
                                            <Col span={12}>{material.quantity} x {material.text}</Col>
                                            <Col span={11}>
                                                <FlexRow justify={"end"}>
                                                    {getNumberAsCurrency(material.quantity * (material.salesPriceExVat * (1 - (material.discount / 100))))} DKK excl. moms
                                                </FlexRow>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        )}

                        <FlexRow justify={"end"}>
                            <Space>
                                <StyledButton onClick={() => setShowCreateInvoiceModal(false)}>Afbryd</StyledButton>
                                <StyledButton onClick={() => createInvoice()} loading={isCreatingInvoiceFromOrder} disabled={isCreatingInvoiceFromOrder} color={"primary"}>Opret {isAdvance ? 'aconto ' : 'slut'}faktura</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </FlexRow>
            </Modal>
        </>
    )
}

const styles = {
    headline: {
        fontSize: 16,
        fontWeight: 700,
        color: '#939393'
    }
}


export default OrderDocuments
