import {queryCache, useMutation, useQuery} from "react-query";
import {request} from "Apis/BaseApi";
import {InvoiceModel} from "Apis/Models/InvoiceModel";
import {openErrorNotification} from "Utils/NotificationUtils";

export const invoicesQueryCacheKey = 'invoices';
export const useInvoices = (queryFilter?: string, state?: string, invoiceType?: string) => {
    const params = new URLSearchParams();

    if (queryFilter) {
        params.set('queryFilter', queryFilter);
    }

    if (state) {
        params.set('state', state);
    }

    if (invoiceType) {
        params.set('invoiceType', invoiceType);
    }

    return useQuery<InvoiceModel[]>([invoicesQueryCacheKey, queryFilter, state, invoiceType], async () => {
        return await request<InvoiceModel[]>({
            url: `/invoices?${params.toString()}`,
            method: 'GET'
        })
    })
}

export const invoiceQueryCacheKey = 'invoice';
export const useInvoice = (id: number) => {
    return useQuery<InvoiceModel>([invoiceQueryCacheKey, id], async () => {
        return await request<InvoiceModel>({
            url: `/invoices/${id}`,
            method: 'GET'
        })
    })
}

export const useCreateInvoiceFromOrder = () => {
    return useMutation(({orderId, isAdvance, selectedAgreements, selectedTimeEntries, joinTimeEntries, selectedOffers, selectedMaterials, joinMaterials, includeTimesheet}: any) => {
        return request<InvoiceModel>({
            url: `/invoices/order/${orderId}`,
            method: 'POST',
            data: {
                isAdvance,
                selectedAgreements,
                selectedTimeEntries,
                selectedOffers,
                selectedMaterials,
                joinMaterials,
                joinTimeEntries,
                includeTimesheet
            }
        })
    })
}

export const useSaveInvoice = () => {
    return useMutation(async (invoice: InvoiceModel) => {
        let response;

        if (invoice.id) {
            response = await request<InvoiceModel>({
                url: `/invoices/${invoice.id}`,
                method: 'PUT',
                data: invoice
            })
        } else {
            response = await request<InvoiceModel>({
                url: `/invoices`,
                method: 'POST',
                data: invoice
            })
        }

        return response;
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceQueryCacheKey)
            queryCache.invalidateQueries([invoiceQueryCacheKey, invoice.id])
        },
    })
}

export const useInvoiceReadyForApproval = () => {
    return useMutation(async (invoice: InvoiceModel) => {
        return await request<InvoiceModel>({
            url: `/invoices/${invoice.id}/readyForApproval`,
            method: 'POST',
        })
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceQueryCacheKey)
            queryCache.invalidateQueries([invoiceQueryCacheKey, invoice.id])
        },
        onError: (error: any) => {
            openErrorNotification('Der mangler informationer', error.data?.message, {
                duration: 10
            })
        }
    })
}

export const useCreateCreditNoteFromInvoice = () => {
    return useMutation(async (invoice: InvoiceModel) => {
        return await request<InvoiceModel>({
            url: `/invoices/${invoice.id}/creditnote`,
            method: 'POST',
        })
    }, {
        onSuccess: (invoice: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceQueryCacheKey)
            queryCache.invalidateQueries([invoiceQueryCacheKey, invoice.id])
        },
        onError: (errors: any) => {
            let error = errors.data;

            if (Array.isArray(errors.data))  {
                error = errors.data.join(' - ')
            }

            openErrorNotification('Der mangler informationer', error, {
                duration: 10
            })
        }
    })
}

export const useDeleteInvoice = () => {
    return useMutation(async (invoice: InvoiceModel): Promise<InvoiceModel> => {
        return await request({
            url: `/invoices/${invoice.id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: (offer: InvoiceModel) => {
            queryCache.invalidateQueries(invoiceQueryCacheKey)
            queryCache.invalidateQueries([invoiceQueryCacheKey, offer.id])
        },
    })
}

export const useResendInvoice = () => {
    return useMutation(async (model: {invoiceId: number, emails: string[]}) => {
        return await request<InvoiceModel>({
            url: `/invoices/${model.invoiceId}/send`,
            method: 'POST',
            data: model.emails
        })
    })
}

export const useRegisterPayment = () => {
    return useMutation(async (model: {date: Date, invoiceId: number}) => {
        return await request({
            url: `/invoices/${model.invoiceId}/registerPayment`,
            method: 'POST',
            data: {date: model.date}
        })
    },
    {
        onSuccess: (model: any) => {
            queryCache.invalidateQueries(invoiceQueryCacheKey)
            queryCache.invalidateQueries([invoiceQueryCacheKey, model.invoiceId])
        },
    }
    )
}
